import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import {Image} from "mui-image";
import defaultUser from "../../assets/images/user.png";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from '@mui/material/InputLabel';
import mail from "../../assets/images/icons/svg/sms.svg";
import Compicon from "../../assets/images/icons/svg/office.svg";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {baseUrl, errorMessage} from "../../config/config";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import {Card, CardActions, CardContent, CardMedia, Checkbox, Divider, FormLabel, Tooltip} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    setEditProfileSideBarState,
    setMessageSnackbarStateState, setMobileAudienceDetailsState,
    setProfileSideBarState,
    setTopUpDetailsState
} from "../../redux/slices/common";

import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import connectorService from "../../services/api/connector.service";
import {setMessage} from "../../redux/slices/message";
import {useForm} from "react-hook-form";
import TopUpDetails from "../../components/common/sidebar/top-up-details-sidebar/TopUpDetails";
import styles from "../../components/common/details/audience-page-details/AudiencePageDetails.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import StripeService from "../../services/api/stripe.service";
import { CheckCircle } from '@mui/icons-material';
import ContactUsModal from "../../components/common/modal/contact-us-modal/ContactUsModal";
import { setContactUsState } from '../../redux/slices/common';
import MetaService from "../../services/api/meta.service";

function UserProfile() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {currentTheme} = useSelector((state) => state.theme);
    const { user } = useSelector((state) => state.auth.auth);
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
    const [connectorData, setConnectorData] = useState([]);
    const { selectedCountry } = useSelector((state) => state.auth);

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const [isNameAvailable, setIsNameAvailable] = useState(null);
    const [isPassAvailable, setIsPassAvailable] = useState(null);
    const [sdkLoaded, setSdkLoaded] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [newTopUpDetailModalState, setNewTopUpDetailModalState] = useState(false);
    const [ amountDetails, setAmountDetails] = useState([]);
    const { topUpDetailsState } = useSelector((state) => state.common);

    useEffect(() => {

        setIsCollapsed(false);

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        window.fbAsyncInit = () => {
            window.FB.init({
                appId            : '769347791795188',
                autoLogAppEvents : true,
                cookie           : true,
                xfbml            : true,
                version          : 'v20.0'
            });
            setSdkLoaded(true);
        };


        if(topUpDetailsState) {
            handleTopUpDetailsClick();
        }

        let mounted = true;
        if (mounted) {


            let userId = user?.id || userFromLocalStorage?.id;

            const queryParams = [
                `userId=${userId}`,
            ];

            connectorService.getAllConnectorsByUser(queryParams).then((data) => {
                if (data) {
                    setConnectorData(data.results);
                }
            });

            const queryCalcParams = [
                `userId=${userId}`,
                `countryId=${selectedCountry.id}`,
            ];


            StripeService.getUserPlanDetails(queryCalcParams.join('&')).then((data) => {

                    if (data?.results) {
                        setAmountDetails(data.results);
                    }
                    return Promise.resolve();
                },
                (error) => {
                    return Promise.reject();
                }
            )
        }

        return () => {
            mounted = false;

        };
    }, []);

    const handleEditProfile = () => {

        dispatch(setEditProfileSideBarState(true));
        dispatch(setProfileSideBarState(false));
    };

    const handleActivityLog = () => {

        navigate('/activity');
    };


    const handleContactUs = () => {

        dispatch(setContactUsState(true));
    };

    const handleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
        dispatch(setMobileAudienceDetailsState(false));
    }


    const switchChange = (connectorId, isAuthorized) => {

        const connector = connectorData.find(connector => connector.id === connectorId);

        if((connectorId !== 2)  && !isAuthorized) {

            if(connectorId === 4) {

                setConnectorData(prevConnectorData => {
                    return prevConnectorData.map(connector => {
                        if (connector.id === connectorId) {
                            return {...connector, isAuthorized: true, isShow: true};
                        }
                        return connector;
                    });
                });
                return;

            } else {

                handleConnect(connectorId)
            }


        } else if (connectorId === 2 && !isAuthorized) {

                // Construct the OAuth URL with the required parameters

                window.FB.login(function(response) {

                    //console.log("response" + JSON.stringify(response));
                    if (response.authResponse) {
                        console.log('Welcome!  Fetching your information.... ');
                        const authorizationCode = response.authResponse.accessToken;

                        if (authorizationCode) {

                            let userId = user?.id || userFromLocalStorage?.id;

                            const queryParams = [
                                `userId=${userId}`,
                                `isAuthorized=${true}`,
                                `connectorId=${connectorId}`,
                                `typeName=${authorizationCode}`,
                            ];

                            //const formData = new FormData(); // Add any form data if needed

                            connectorService.updateStatusConnector(queryParams.join('&')).then(
                                (data) => {

                                    if (data?.results) {
                                        dispatch(setMessage({
                                            type: "success",
                                            title: data?.message ? data.message : "Connector status change successfully",
                                        }));
                                        dispatch(setMessageSnackbarStateState(true));


                                        setConnectorData(prevConnectorData => {
                                            return prevConnectorData.map(connector => {
                                                if (connector.id === connectorId) {
                                                    return { ...connector, isAuthorized: true, isShow: false };
                                                }
                                                return connector;
                                            });
                                        });

                                    } else {
                                        dispatch(setMessage({
                                            type: "error",
                                            title: data?.message ? data.message : errorMessage,
                                        }));
                                        dispatch(setMessageSnackbarStateState(true));
                                    }
                                    return Promise.resolve();
                                },
                                (error) => {

                                    console.log(error);
                                    dispatch(setMessage({
                                        type: "error",
                                        title: error.response.data.message,
                                    }));
                                    dispatch(setMessageSnackbarStateState(true));
                                    return Promise.reject(error);
                                }
                            );
                        }
                    } else {
                        console.log('User cancelled login or did not fully authorize.');
                    }
                }, {
                    config_id: '1163788074913841',
                });
            return;
        }

        if (isAuthorized && !connector.isShow) {

            const queryParams = [
                `userId=${user != null ? user.id : userFromLocalStorage.id}`,
                `connectorId=${connectorId}`,
                `status=${false}`,
                `isAuthorized=${false}`
            ];

            connectorService.updateStatusConnector(queryParams.join('&')).then(
                (data) => {

                    if (data?.results) {
                        dispatch(setMessage({
                            type: "success",
                            title: "Campaign connector disabled successfully",
                        }));
                        dispatch(setMessageSnackbarStateState(true));


                        setConnectorData(prevConnectorData => {
                            return prevConnectorData.map(connector => {
                                if (connector.id === connectorId) {
                                    return { ...connector, isAuthorized: false, isShow: false, isChecked: false };
                                }
                                return connector;
                            });
                        });

                        if (connectorId === 2) {

                            window.FB.getLoginStatus(response => {
                                    if (response.status === 'connected') {
                                        window.FB.logout();
                                    } else {
                                        console.log('User is not logged in');
                                    }
                                });
                        }


                    } else {
                        dispatch(setMessage({
                            type: "error",
                            title: data?.message ? data.message : errorMessage,
                        }));
                        dispatch(setMessageSnackbarStateState(true));
                    }
                    return Promise.resolve();
                },
                (error) => {

                    console.log(error);
                    dispatch(setMessage({
                        type: "error",
                        title: error.response.data.message,
                    }));
                    return Promise.reject(error);
                }
            );

            return;
        }

        if (connectorId === 4) {

            setConnectorData(prevConnectorData => {
                return prevConnectorData.map(connector => {
                    if (connector.id === connectorId) {
                        return {...connector, isChecked: false, isShow: false, isAuthorized: false};
                    }
                    return connector;
                });
            });
        } 

        if (connector.isChecked && connector.id !== 4) {

            setConnectorData(prevConnectorData => {
                return prevConnectorData.map(connector => {
                    if (connector.id === connectorId) {
                        return {...connector, isChecked: false, isShow: false};
                    }
                    return connector;
                });
            });

        } else if (connectorId !== 4) {

            setConnectorData(prevConnectorData => {

                return prevConnectorData.map(connector => {
                    if (connector.id === connectorId) {
                        return { ...connector, isChecked: true, isShow: true };
                    }
                    return connector;
                });
            });
        }
    }


    const handleConnect = (connectorId) => {

        if (!isNameAvailable  && connectorId === 4) {
            dispatch(setMessage({
                type: "error",
                title: "Username name is Required",
            }));
            dispatch(setMessageSnackbarStateState(true));
            return false;
        }

       if (!isPassAvailable && connectorId === 4) {
           dispatch(setMessage({
               type: "error",
               title: "Password name is Required",
           }));
           dispatch(setMessageSnackbarStateState(true));
           return false;
       }


        const connector = connectorData.find(connector => connector.id === connectorId);

        const queryParams = [
            `userId=${user != null ? user.id : userFromLocalStorage.id}`,
            `connectorId=${connectorId}`,
            `isAuthorized=${true}`,
        ];

        if (connector && connector.typeName) {

            queryParams.push(`typeName=${connector.typeName}`);
        }

        if (connector && connector.typePass) {

            queryParams.push(`typePass=${connector.typePass}`);
        }

        connectorService.updateStatusConnector(queryParams.join('&')).then(
            (data) => {

                if (data?.results) {
                    dispatch(setMessage({
                        type: "success",
                        title: data?.message ? data.message : "Connector status change successfully",
                    }));
                    dispatch(setMessageSnackbarStateState(true));


                    setConnectorData(prevConnectorData => {
                        return prevConnectorData.map(connector => {
                            if (connector.id === connectorId) {
                                return { ...connector, isAuthorized: true, isShow: false };
                            }
                            return connector;
                        });
                    });

                } else {
                    dispatch(setMessage({
                        type: "error",
                        title: data?.message ? data.message : errorMessage,
                    }));
                    dispatch(setMessageSnackbarStateState(true));
                }
                return Promise.resolve();
            },
            (error) => {

                console.log(error);
                dispatch(setMessage({
                    type: "error",
                    title: error.response.data.message,
                }));
                dispatch(setMessageSnackbarStateState(true));
                return Promise.reject(error);
            }
        );
    };

    //const staticNames = ['adv_123', 'adv_1234', 'adv_12345'];

    const handleName = (event, connectorId) => {

        const name = event.target.value.toLowerCase();

        if (name.trim() === '') {

            setIsNameAvailable(false);


        } else {

            setConnectorData(prevConnectorData => {
                return prevConnectorData.map(connector => {
                    if (connector.id === connectorId) {
                        return { ...connector, typeName: name };
                    }
                    return connector;
                });
            });

            setIsNameAvailable(true);

            // if(connectorId === 1) {
            //     setIsNameAvailable(staticNames.includes(name));
            // } else {
            //     setIsNameAvailable(true);
            // }
        }
    };

    const handlePass = (event, connectorId) => {
        const name = event.target.value;

        if (name.trim() === '') {

            setIsPassAvailable(false);
        } else {

            setConnectorData(prevConnectorData => {
                return prevConnectorData.map(connector => {
                    if (connector.id === connectorId) {
                        return { ...connector, typePass: name };
                    }
                    return connector;
                });
            });
            setIsPassAvailable(true);
        }
    };

    const handleTopUpDetailsClick = () => {
        setIsCollapsed(!isCollapsed);
        dispatch(setTopUpDetailsState(true));
        dispatch(setProfileSideBarState(false));
    }

    const handleTopUpDetailsCloseClick = () => {
        setIsCollapsed(!isCollapsed);
        dispatch(setTopUpDetailsState(false));
        dispatch(setProfileSideBarState(false));
    }


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({theme, isAuthorized}) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#06e60d',
                '& + .MuiSwitch-track': {
                    backgroundColor: currentTheme === 'dark' ?  '#ffffff' : '#e4e4e4',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
            '&:hover .MuiSwitch-thumb': {
                backgroundColor: isAuthorized ? '#ff0000': '#33cf4d', // Change colors based on condition
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#353535' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const StyledCheckCircleIcon = styled(CheckCircle)`
      width: 20px;
      height: 20px;
      color: #4dbf39 !important; /* Assuming $green is a SCSS variable, replace it with actual color value */
      &.MuiInputBase-root .MuiSvgIcon-root {
        color: #4dbf39 !important; /* Apply the color */
      }
    `;



    return (<>
        <Box className="FullArea" sx={{
            position: 'absolute',
            overflowX: 'hidden',
            height: '100vh',
            width :'100%',
            top: 0,
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box className="UserProfileArea" sx={{flex: 1, display: 'flex'}}>
                {isCollapsed &&
                <Button className={`${styles.LogoutButton ?? ''}`} onClick={handleTopUpDetailsCloseClick} sx={{ backgroundColor: currentTheme === 'dark' ? '#ffffff' : '#202020', color: 'red' ,width: 55, height: 55, minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', position: 'absolute', left: `calc(100% - 30% - 120.5px)`, top: 40, zIndex: 2000,'&:hover': { background: 'white !important' }  }}>
                    <CloseIcon sx={{ width: 25, height: 25 }} />
                </Button>
               }
                    <Box  className="UserProfileAreaInner" sx={{width: '100%',}}>
                    <Avatar sx={{bgcolor: 'secondary.main', width: 120, height: 120}}>
                        <Image src={user?.path ? user.path : defaultUser}/>
                    </Avatar>

                    <Chip label={ amountDetails.planType } style={{marginBottom: '0px'}} variant="contained" color='primary'
                          spacing={10} sx={{transform: 'translateY(-20px)', width: '120px'}}/>

                        <Typography component="h3" style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020'}}
                                    variant="contained">
                            { user?.name ? user.name : "" }
                        </Typography>
                        <Grid container rowSpacing={0} columnSpacing={{xs: 0.5, sm: 2, md: 3}}>
                            <Grid style={{ paddingTop: '8px' }} item xs={6}>
                                <FormControlLabel
                                    control={ <Image src={mail} alt="companyicon"
                                                      style={{width: 20, height: 20, objectFit: 'contain', marginRight: '8px'}}/>}
                                    label="Email"
                                    sx={{ marginLeft: '1px',  color: '#6F7288' }}

                                />
                            </Grid>
                            <Grid item xs={6}
                                  style={{padding: '0px', color: currentTheme === 'dark' ? '#ffffff' : '#202020', marginLeft: '-95px'}}>
                                <Box sx={{p: 2}}>{ user?.email ? user.email : ""}</Box>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="company-textfield"
                                    name="company-textfield"
                                    value={'Company'}
                                    variant="outlined"
                                    sx={{padding: '0px', border: 'none', "& fieldset": {border: 'none'}}}
                                    InputProps={{
                                        style: {color: "#6F7288", padding: '0px'},
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Image src={Compicon} alt="companyicon"
                                                       style={{width: 20, height: 20, objectFit: 'contain'}}/>
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            style: {padding: '2px'}
                                        }
                                    }}
                                    inputProps={
                                        {readOnly: true,}
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} style={{padding: '0px', color: currentTheme === 'dark' ? '#ffffff' : '#202020', marginLeft: '-80px'}}>
                                <Box sx={{
                                    p: 2,
                                    padding: '0px',
                                    color: currentTheme === 'dark' ? '#ffffff' : '#202020'
                                }}>{user?.companyName ? user.companyName : ""}</Box>
                            </Grid>
                            <Grid item style={{paddingTop: '10px'}} xs={12}>
                                <Typography component="h3"
                                            style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020'}}
                                            variant="contained">
                                    Country
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {user?.countries?.length &&
                                    <Stack sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                        <List sx={{display: 'flex', flexDirection: 'row'}}>
                                            {user.countries.map((country, index) => {
                                                return (
                                                    <ListItem disablePadding key={index} sx={{mx: 0}}>
                                                        <Avatar sx={{transform: 'scale(.7)'}}>
                                                            <img src={baseUrl + country.path} alt="#"/>
                                                        </Avatar>
                                                        <ListItemText sx={{
                                                            padding: "10px",
                                                            color: "#6F7288"
                                                        }}>{country.code ? country.code : country.name}</ListItemText>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid container  rowSpacing={0} columnSpacing={{xs: 0.5, sm: 2, md: 3}}>
                            <Grid item xs={12} container >
                        <Card className="card"
                              style={{
                                  width: '70%',
                                  background: currentTheme === 'dark' ? '#0B0E24' : '#F1F1F1',
                                  border: `1px solid  ${currentTheme === 'dark' ? '#212330' : '#F1F1F1'}`,
                                  borderRadius: '16px'
                              }}>

                            <CardContent className="fiCardContent" style={{paddingBottom: '6px'}}>

                                <Typography
                                    variant="body2"
                                    className="fiCardContentTextSecondary"
                                    component="p"
                                    style={{
                                        color: currentTheme === 'dark' ? '#ffffff' : undefined, // Font color white if dark theme
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontFamily: 'Manrope, Arial, sans-serif',
                                        paddingBottom: '5px'
                                    }}
                                >

                                    { amountDetails.planType  }  {amountDetails.expiryMessage}
                                </Typography>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="body2"
                                            className="fiCardContentTextSecondary"
                                            component="p"
                                            style={{
                                                color: '#6F7288',
                                                fontFamily: 'Manrope, Arial, sans-serif'
                                            }}
                                        >

                                            Available Monthly Cards:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="body2"
                                            className="fiCardContentTextSecondary"
                                            component="p"
                                            style={{
                                                color: '#6F7288',
                                                fontFamily: 'Manrope, Arial, sans-serif'
                                            }}
                                        >

                                            Available Monthly Audience:
                                        </Typography>

                                    </Grid>
                                    <Grid item xs={6} container alignItems="center">
                                        <Typography variant="body1" color="primary" component="span">
                                            { amountDetails.cardPlanLimit !== undefined ? amountDetails.cardPlanLimit.toLocaleString() : '0' }
                                        </Typography>
                                        <Typography variant="body1" style={{ color: '#39BF8F' }} component="span">
                                            / { amountDetails.cardTotalPlanLimit !== undefined ? amountDetails.cardTotalPlanLimit.toLocaleString() : '0' }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} container alignItems="center">
                                        <Typography variant="body1" color="primary" component="span">
                                            { amountDetails.audiencePlanLimit !== undefined ? amountDetails.audiencePlanLimit.toLocaleString() : '0' }
                                        </Typography>
                                        <Typography variant="body1" style={{ color: '#39BF8F' }} component="span">
                                            / { amountDetails.audiencePlanTotalLimit !== undefined ? amountDetails.audiencePlanTotalLimit.toLocaleString() : '0' }
                                        </Typography>
                                    </Grid>
                                </Grid>
                                { (amountDetails.toppedUpAudience > 0 || amountDetails.toppedUpCards > 0) &&
                                    <Grid container style={{paddingTop: '6px'}}>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="body2"
                                                className="fiCardContentTextSecondary"
                                                component="p"
                                                style={{
                                                    color: '#6F7288',
                                                    fontFamily: 'Manrope, Arial, sans-serif'
                                                }}
                                            >

                                                Topped-up Cards:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="body2"
                                                className="fiCardContentTextSecondary"
                                                component="p"
                                                style={{
                                                    color: '#6F7288',
                                                    fontFamily: 'Manrope, Arial, sans-serif'
                                                }}
                                            >

                                                Topped-up Audience:
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6} container alignItems="center">
                                            <Typography variant="body1" color="primary" component="span">
                                                {amountDetails.toppedUpCards !== undefined ? amountDetails.toppedUpCards.toLocaleString() : '0'}
                                            </Typography>
                                            <Typography variant="body1" style={{color: '#39BF8F'}} component="span">
                                                / {amountDetails.totalToppedUpCards !== undefined ? amountDetails.totalToppedUpCards.toLocaleString() : '0'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} container alignItems="center">
                                            <Typography variant="body1" color="primary" component="span">
                                                {amountDetails.toppedUpAudience !== undefined ? amountDetails.toppedUpAudience.toLocaleString() : '0'}
                                            </Typography>
                                            <Typography variant="body1" style={{color: '#39BF8F'}} component="span">
                                                / {amountDetails.totalToppedUpAudience !== undefined ? amountDetails.totalToppedUpAudience.toLocaleString() : '0'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </CardContent>
                            <CardActions className="fiCardContent" style={{paddingBottom: '10px'}}>
                                <Button variant='contained'
                                        style={{border: '1px', borderRadius: '32px', background: '#3C4DE7'}} onClick={handleTopUpDetailsClick}> Top up your account </Button>
                            </CardActions>
                        </Card>
                            </Grid>
                            <Grid item xs={12} container>
                            <Grid item xs={4.2} style={{paddingTop: '8px'}}>
                                <Button variant='contained' fullWidth sx={{
                                    textTransform: "none",
                                    color: currentTheme === 'dark' ? '#ffffff' : undefined,
                                    background: '#3C4DE7',
                                    width : '100%'
                                }}
                                        onClick={handleEditProfile}>Edit Profile</Button>
                            </Grid>
                            <Grid item xs={4.2} style={{paddingLeft: '5px', paddingTop: '8px'}}>
                                <Button variant='outlined' fullWidth sx={{
                                    textTransform: "none",
                                    color: currentTheme === 'dark' ? '#ffffff' : undefined,
                                    width : '100%'
                                }}
                                        onClick={handleActivityLog}>Activity log</Button>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box  sx={{width: '100%', overflow: 'scroll',  overflowX: 'hidden', paddingLeft:'10px'}}>
                        <Grid className="UserProfileAreaInner2" container rowSpacing={0} columnSpacing={{xs: 0.5, sm: 2, md: 3}}>
                            <Grid style={{ paddingTop: '15px'}} item xs={12}>
                                <Typography component="h1"
                                            style={{color: currentTheme === 'dark' ? '#ffffff' : '#202020'}}
                                            variant="contained">
                                    Linked Accounts
                                </Typography>
                            </Grid>
                            {connectorData &&
                                connectorData.map((connector, index) => (
                            <Grid container key={index} style={{paddingTop: '15px', paddingLeft: '20px'}} item xs={12}>
                                <Grid item xs={6} style={{padding: '16px'}}>
                                    <Typography component="h3"
                                                style={{color: currentTheme === 'dark' ? '#ffffff' : '#202020'}}
                                                variant="contained">
                                        <span>{connector.name}</span>
                                        <span style={{marginLeft: '8px', fontWeight: 'bold', fontSize: '14px' }}>
                                        {!(connector.id === 3 || connector.id === 1) && `(${connector.conLabelName})`}
                                        </span>
                                    </Typography>
                                <Button  size={"small"} style={{
                                    marginLeft: '10px',
                                    width: '80px',
                                    height: '22px',
                                    color: currentTheme === 'dark' ? '#ffffff' : '#202020',
                                    background: connector.isAuthorized ? '#73A89E' : '#39BF8F',
                                    border: '1px',
                                    borderRadius: '45px',
                                    fontSize: '10px'
                                }} spacing={5}>  {connector.isAuthorized ? 'Connected' : 'connect' } </Button>
                                </Grid>
                                <Grid item xs={6} style={{paddingTop: '15px' , display: 'flex', justifyContent: 'space-around', marginLeft: '-55px'}}>
                                    <Tooltip title={connector.isAuthorized ? 'Disconnect' : 'Connect' } PopperProps={{ modifiers: [{name: "offset", options: {offset: [ 65, -80],},},],}}>
                                        <Box display="inline-block" position="relative">
                                        <IOSSwitch
                                            sx={{ marginRight: 0, width: '45px' }}
                                            onClick={() => switchChange(connector.id, connector.isAuthorized)}
                                            checked={!!connector.isAuthorized || !!connector.isChecked}
                                            isAuthorized={connector.isAuthorized}
                                        />
                                        </Box>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop: '5px' , paddingLeft: '15px', display: connector.isShow && connector.id === 4 ?  'flex': 'none', border: '2px', borderRadius: '25px'}}>
                                    <Box style={{ border: '1px' , borderRadius: '10px', width: '70%' , height:'110%', color: '#3955BF', borderStyle: 'solid' }}>
                                        <Grid container>
                                        <Grid item style={{paddingTop : '15px', paddingLeft: '15px'}} xs={12} container alignItems="center">
                                            <TextField variant="standard"
                                                       placeholder={connector.typeLabelName}
                                                       inputProps={{ style: { color: currentTheme === 'dark' ? '#ffffff' : undefined , fontSize: '14px' } }}
                                                       style={{ width: "420px", height: "45px", display: connector.isTypeName ? 'block' : 'none' }}
                                                       InputProps={{
                                                           style: {color: "#6F7288", padding: '0px'},
                                                           startAdornment: (
                                                               <InputAdornment position="start">
                                                                   <Image src={mail} alt="companyicon" style={{width: 25, height: 25, objectFit: 'contain'}}/>
                                                               </InputAdornment>
                                                           ),
                                                           // endAdornment: isNameAvailable ? (
                                                           //     <InputAdornment position="start">
                                                           //         <CheckCircle style={{ color: '#4dbf39', width: 20, height: 20 }} />
                                                           //     </InputAdornment>
                                                           // ) : null,
                                                           inputProps: {
                                                               style: {padding: '12px'}
                                                           }
                                                       }}
                                                       fullWidth
                                                       onChange={(e) => handleName(e, connector.id)}
                                            />
                                        </Grid>
                                        <Grid item style={{paddingLeft: '15px', paddingTop: '30px'}} xs={12} container alignItems="center">
                                            <TextField type="password" variant="standard" placeholder={connector.typeLabelPass}
                                                       inputProps={{ style: { color: currentTheme === 'dark' ? '#ffffff' : undefined , fontSize: '14px' } }}
                                                       style={{ width: "420px", height: "45px", display: connector.isTypePass ?  'block' : 'none' }}
                                                       InputProps={{
                                                           style: {color: "#6F7288", padding: '0px'},
                                                           startAdornment: (
                                                               <InputAdornment position="start">
                                                                   <Image src={Compicon} alt="companyicon"
                                                                          style={{width: 20, height: 20, objectFit: 'contain'}}/>
                                                               </InputAdornment>
                                                           ),
                                                           inputProps: {
                                                               style: {padding: '12px'}
                                                           }
                                                       }}
                                                       onChange={(e) => handlePass(e, connector.id)}
                                                       fullWidth
                                            />
                                        </Grid>
                                            <Grid item style={{ paddingTop: '14px', marginLeft: '-25px'}} xs={12}  alignItems="center">
                                            <Button
                                                variant='contained'
                                                style={{ width: '20%' , marginLeft: '80%', fontSize: '8px', color: currentTheme === 'dark' ? '#000000': '#ffffff', background: '#39BF8F', borderRadius: '20px' }}
                                                onClick={() => handleConnect(connector.id)}
                                            >
                                                Connect
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid> ))}
                        </Grid>
                    </Box>
            </Box>
            <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', paddingLeft: '15px', justifyContent: 'flex-end', paddingBottom: '20px'}}>
                <Divider sx={{width: '100%', my: 2, background: currentTheme === 'dark' ? '#1b2440' : 'rgba(255,255,255,0.68)'}}/>
                <Grid container rowSpacing={0} columnSpacing={{xs: 0.5, sm: 1, md: 2}} style={{ marginLeft: '20px' }}>
                    <Grid style={{padding: '2px'}} item xs={6}>
                        <Typography sx={{
                            fontWeight: '700',
                            mb: 2,
                            textAlign: 'left',
                            color: currentTheme === 'dark' ? '#ffffff' : '#202020'
                        }}>
                            Have questions?
                        </Typography>
                        <Typography variant="body2" color={'#6F7288'}>
                            Please <a onClick={handleContactUs}>contact us</a> for any inquiry or feedback about our
                            platform and our team will promptly respond.
                        </Typography>
                    </Grid>
                    <Grid style={{ padding: '2px', display: 'flex', justifyContent: 'flex-end', marginLeft: '-210px' }} item xs={6}>
                        <Button variant='contained' color='warning' className="ContactUsBtn" sx={{ textTransform: "none", fontSize: '20px' }}  onClick={handleContactUs}>Contact us</Button>
                    </Grid>
                </Grid>
            </Box>

            <TopUpDetails
                // audience={audienceDetails}
                handleCollapsed={handleCollapsed}
                newTopUpDetailModalState={newTopUpDetailModalState}
                setNewTopUpDetailModalState={setNewTopUpDetailModalState}
            />

            <ContactUsModal/>
        </Box>
   </>);
}

export default UserProfile;