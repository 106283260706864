export const defaultCountries = [
  {
    "name": "OMAN",
    "path": "images/flags/c_om.png",
    "center": [55.975400, 21.473500],
    "zoom": 6,
    "code": "OM"
  },
  {
    "name": "UNITED ARAB EMIRATES",
    "path": "images/flags/c_uae.png",
    "center": [53.847800, 23.424100],
    "zoom": 5,
    "code": "AE"
  },
  {
    "name": "KUWAIT",
    "path": "images/flags/c_kw.png",
    "center": [47.977400, 29.375900],
    "zoom": 8,
    "code": "KW"
  },
  {
    "name": "QATAR",
    "path": "images/flags/c_qatar.png",
    "center": [51.183900, 25.354800],
    "zoom": 8,
    "code": "QA"
  },
  {
    "name": "SAUDI ARABIA",
    "path": "images/flags/c_ksa.png",
    "center": [46.738586, 23.000000],
    "zoom": 5,
    "code": "SA"
  },
  {
    "name": "BAHRAIN",
    "path": "images/flags/c_bh.png",
    "center": [50.54715605160926, 26.042688864224925],
    "zoom": 9,
    "code": "BH"
  },
  {
    "name": "UNITED STATES",
    "path": "images/flags/c_usa.png",
    "center": [-101.299591, 47.116386],
    "zoom": 2,
    "code": "US"
  },
  {
    "name": "SPAIN",
    "path": "images/flags/c_esp.png",
    "center": [-3.703790, 40.416775],
    "zoom": 9,
    "code": "ES"
  },
  {
    "name": "LEBANON",
    "path": "images/flags/c_lb.png",
    "center": [35.8623, 33.8547],
    "zoom": 9,
    "code": "LB"
  },
  {
    "name": "GERMANY",
    "path": "images/flags/c_de.png",
    "center": [10.4515, 51.1657],
    "zoom": 8,
    "code": "DE"
  }
];